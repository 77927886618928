import { useQuery } from '@apollo/client';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { TermsOfUse } from 'components/TermsOfUse/TermsOfUse';
import { Button } from 'components/v2/Buttons/Button';
import {
  DateField,
  PhoneFieldBasic,
  PreferredTimezoneSelectField,
  SelectField,
  TextField,
  ZipField
} from 'components/v2/Form';
import { AutocompleteField } from 'components/v2/Form/AutocompleteField/AutocompleteField';
import { PasswordFieldWithHints } from 'components/v2/Form/PasswordFieldWithHints/PasswordFieldWithHints';
import { SelectWithOptions } from 'components/v2/Form/SelectWithOptions/SelectWithOptions';
import { LegalAgreements } from 'components/v2/LegalAgreements/LegalAgreements';
import { DropdownOption, utils } from 'kb-shared';
import { BugTracker } from 'kb-shared/utilities/bugTracker';
import { isInputValid } from 'kb-shared/utilities/validation';
import { SignUpFormProps } from 'screens/Book/components/SignUp.types';
import { analytics } from 'utilities/analytics';
import { dateToString, getUtcDate, stringToDateUTC } from 'utilities/formatDate';
import { pageUrl } from 'utilities/pageUrl';
import {
  defaultOption,
  getInitialPatientData,
  hasEmailInput,
  hasPasswordInput,
  isReferralSourceDetailsVisible,
  isReferralInfluencerDetailsVisible,
  isReferralProviderDetailsVisible,
  isUS,
  validateSignUpData
} from 'utilities/userUtil';
import { OptionParams, SIGNUP_TYPE } from 'utilities/userUtil.types';

import { GoToSignIn } from './GoToSignIn/GoToSignIn';
import { QUERY_LABS, QUERY_REFERRING_PROVIDERS } from './SignUpForm.graphql';
import {
  ButtonWrapperPrivacy,
  Form,
  InputWrapperMargin,
  TwoFieldWrapper,
  VMarginWrapper
} from './SignUpForm.styled';
import { AvailableLabs, ReferringProviders } from './SignUpForm.types';
import { sortLabsOptions } from './SignUpForm.utils';
import { SignUpFormModal } from './SignUpFormModal';

const { GENDER_INPUT_DEFAULTS, COUNTRIES_OPTIONS, states } = utils;

export const SignUpForm = (props: SignUpFormProps) => {
  const {
    loading,
    onSignUpPatient,
    signUpType,
    preselectedLab,
    patient,
    signInLinkIsVisible
  } = props;
  const [referralSourcesLoadStatus, setReferralSourcesLoadStatus] = useState<boolean | undefined>(
    undefined
  );
  const [timeZonesLoadStatus, setTimeZonesLoadStatus] = useState<boolean | undefined>(undefined);

  const [patientData, setPatientData] = useState(getInitialPatientData(patient));
  const { data: availableLabs, loading: loadingLabs, error: errorLabs } = useQuery<AvailableLabs>(
    QUERY_LABS
  );
  const { data: availableReferringProviders } = useQuery<ReferringProviders>(
    QUERY_REFERRING_PROVIDERS,
    {
      onError: error => BugTracker.notify(error, 'ReferringProviders')
    }
  );
  const history = useHistory();
  const isBasicUserInfoEventSent = useRef(false);

  const handleBeforeUnload = (e: any) => {
    if (!e) return;
    // there is no need for popup, if patient didn't make any changes on the form
    if (
      JSON.stringify(patientData.patient) === JSON.stringify(getInitialPatientData(patient).patient)
    )
      return;

    e.preventDefault();
    e.returnValue = '';
  };

  useEffect(() => {
    if (analytics) {
      if (signUpType === SIGNUP_TYPE.EMAIL) analytics.page(analytics.PAGES.EMAIL_SIGN_UP);
      if (signUpType === SIGNUP_TYPE.GOOGLE) analytics.page(analytics.PAGES.GOOGLE_SIGN_UP);
      if (signUpType === SIGNUP_TYPE.APPLE) analytics.page(analytics.PAGES.APPLE_SIGN_UP);
      if (signUpType === SIGNUP_TYPE.MEDTRONIC) analytics.page(analytics.PAGES.MEDTRONIC_SIGN_UP);
      if (signUpType === SIGNUP_TYPE.DISNEY) analytics.page(analytics.PAGES.DISNEY_SIGN_UP);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientData]);

  useEffect(() => {
    if (!availableLabs) return;

    // When labs are fetched from the server and preselected lab isn't the default lab, we need to select preselected lab
    if (preselectedLab && preselectedLab.id !== defaultOption.value) {
      updatePatientLab({ label: preselectedLab.name, value: preselectedLab.id }, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableLabs]);

  useEffect(() => {
    if (isUS(patientData?.countryOption?.value || '')) {
      updatePatientLab(defaultOption, false);
    } else {
      const other = getAllLabOptions()?.find(lab => lab.label === 'Other');
      const labOption = other ? { label: other.label, value: other.value } : defaultOption;
      updatePatientLab(labOption, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientData?.countryOption]);

  const checkIfBasicUserInfoSent = () => {
    const { firstName, lastName, email } = patientData.patient;

    const isFirstNameValid = firstName && patientData.firstNameInputValid;
    const isLastNameValid = lastName && patientData.lastNameInputValid;
    const isEmailValid = email && patientData.emailInputValid;

    if (isFirstNameValid && isLastNameValid && isEmailValid && !isBasicUserInfoEventSent.current) {
      isBasicUserInfoEventSent.current = true;
      analytics.track(analytics.EVENTS.ACCOUNT_BASIC_INFO_ENTERED);
    }
  };

  const getAllLabOptions = () => {
    const labsAsOptions = availableLabs?.labs.map(lab => {
      return { label: lab.name, value: lab.id };
    });

    // If there is a preselected lab, but that lab doesn't exist among available labs, manually add preselected lab.
    // This situation happens, when preselected lab isn't patient facing lab.
    if (
      labsAsOptions &&
      preselectedLab != null &&
      labsAsOptions.find(lab => lab.value === preselectedLab.id) == null
    ) {
      labsAsOptions.push({ label: preselectedLab.name, value: preselectedLab.id });
    }

    return labsAsOptions;
  };

  const availableLabOptions = (): OptionParams[] | undefined => {
    const labsAsOptions = getAllLabOptions();
    return labsAsOptions?.sort(sortLabsOptions);
  };

  const updatePatientData = (field: string, event: { currentTarget: { value: string } }) => {
    const value = event.currentTarget.value;
    const updatedPatient = { ...patientData };
    if (
      updatedPatient.patient.address &&
      (field === 'city' ||
        field === 'zipcode' ||
        field === 'state' ||
        field === 'address1' ||
        field === 'address2')
    ) {
      updatedPatient.patient.address = { ...updatedPatient.patient.address, [field]: value };
    } else {
      updatedPatient.patient = { ...updatedPatient.patient, [field]: value };
    }

    const errors = validateSignUpData(updatedPatient.patient, signUpType);
    setPatientData({
      ...updatedPatient,
      [`${field}InputValid`]: !Boolean(errors[field])
    });
  };

  const updatePatientEmail = (email: string) => {
    const formatted_email = email.replace(/\s/g, '');
    const updatedPatient = {
      ...patientData,
      patient: {
        ...patientData.patient,
        email: formatted_email
      }
    };
    const errors = validateSignUpData(updatedPatient.patient, signUpType);
    setPatientData({
      ...updatedPatient,
      emailInputValid: !Boolean(errors.email)
    });
  };

  const updatePatientLab = (labOption: { label: string; value: string }, validateLab = true) => {
    const updatedPatient = { ...patientData };
    let newLab = availableLabs?.labs.find(lab => lab.id === labOption.value) || null;
    if (newLab == null && preselectedLab != null) {
      newLab = preselectedLab;
    }
    updatedPatient.patientLab = newLab;
    updatedPatient.patient.labId = newLab ? parseInt(newLab.id) : 0;
    updatedPatient.labOption = labOption;

    const errors = validateSignUpData(updatedPatient.patient, signUpType);
    setPatientData({
      ...updatedPatient,
      patientLabInputValid: !validateLab || !Boolean(errors.patientLab)
    });
  };

  const updatePreferredTimezone = (timeZoneOption: { label: string; value: string }) => {
    const updatedPatient = { ...patientData };
    updatedPatient.patient.preferredTimezone = timeZoneOption.label;
    updatedPatient.preferredTimeZoneOption = timeZoneOption;
    const errors = validateSignUpData(updatedPatient.patient, signUpType);
    setPatientData({
      ...updatedPatient,
      preferredTimezoneInputValid: !Boolean(errors.preferredTimezone)
    });
  };

  const updateGender = (gender: { label: string; value: string }) => {
    const updatedPatient = { ...patientData };
    updatedPatient.patient.gender = gender.value;
    updatedPatient.genderOption = gender;
    setPatientData(updatedPatient);
  };

  const updateCountry = (country: { label: string; value: string }) => {
    const updatedPatient = { ...patientData };

    if (updatedPatient.patient.address == null) return;

    updatedPatient.patient.address.country = country.value;
    updatedPatient.countryOption = country;
    updatedPatient.patient.address.state = '';
    updatedPatient.stateOption = defaultOption;
    updatedPatient.patient.address.zipcode = '';
    updatedPatient.patient.phone = '';
    updatedPatient.patient.address.city = '';
    setPatientData(updatedPatient);
  };

  const updateState = (state: { label: string; value: string }) => {
    const updatedPatient = { ...patientData };

    if (updatedPatient.patient.address == null) return;

    updatedPatient.patient.address.state = state.value;
    updatedPatient.stateOption = state;
    const errors = validateSignUpData(updatedPatient.patient, signUpType);
    setPatientData({
      ...updatedPatient,
      stateInputValid: !Boolean(errors.state)
    });
  };

  const updateBirthdate = (date: string) => {
    const updatedPatient = { ...patientData };
    updatedPatient.patient.birthdate = date;

    const errors = validateSignUpData(updatedPatient.patient, signUpType);
    setPatientData({
      ...updatedPatient,
      birthdateInputValid: !Boolean(errors.birthdate)
    });
  };

  const updateReferralSource = ({ value }: DropdownOption) => {
    const updatedPatient = { ...patientData };
    updatedPatient.patient.referralSource = value;

    if (!isReferralSourceDetailsVisible(value)) {
      updatedPatient.patient.referralSourceDetails = '';
    }

    const errors = validateSignUpData(updatedPatient.patient, signUpType);
    setPatientData({
      ...updatedPatient,
      referralSourceInputValid: !Boolean(errors.referralSource),
      referralSourceDetailsInputValid: undefined
    });
  };

  const toggleSendSmsAppointmentNotificationFlag = () => {
    const updatedPatient = { ...patientData };
    updatedPatient.patient = {
      ...updatedPatient.patient,
      sendSmsAppointmentNotification: !patientData.patient.sendSmsAppointmentNotification
    };
    setPatientData({ ...updatedPatient });
  };

  const toggleSendMaketingEmailFlag = () => {
    const updatedPatient = { ...patientData };
    updatedPatient.patient = {
      ...updatedPatient.patient,
      sendMarketingEmail: !patientData.patient.sendMarketingEmail
    };
    setPatientData({ ...updatedPatient });
  };

  const onSignUp = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    const errors = validateSignUpData(patientData.patient, signUpType);
    const errorsKeys = Object.keys(errors);
    if (errorsKeys.length) {
      // scroll to an element with invalid input
      document
        .getElementById(`input-${errorsKeys[0]}Input`)
        ?.scrollIntoView({ behavior: 'smooth' });

      setPatientData({
        ...patientData,
        emailInputValid: !Boolean(errors.email),
        firstNameInputValid: !Boolean(errors.firstName),
        lastNameInputValid: !Boolean(errors.lastName),
        address1InputValid: !Boolean(errors.address1),
        stateInputValid: !Boolean(errors.state),
        cityInputValid: !Boolean(errors.city),
        zipcodeInputValid: !Boolean(errors.zipcode),
        phoneInputValid: !Boolean(errors.phone),
        birthdateInputValid: !Boolean(errors.birthdate),
        preferredTimezoneInputValid: !Boolean(errors.preferredTimezone),
        patientLabInputValid: signUpType === SIGNUP_TYPE.DISNEY || !Boolean(errors.patientLab),
        referralSourceInputValid: !Boolean(errors.referralSource),
        referralSourceDetailsInputValid:
          isReferralSourceDetailsVisible(patientData.patient.referralSource) &&
          !Boolean(errors.referralSourceDetails),
        passwordInputValid: !Boolean(errors.password)
      });
      return;
    }

    if (analytics) {
      if (signUpType === SIGNUP_TYPE.EMAIL) analytics.track(analytics.EVENTS.EMAIL_SIGN_UP_STARTED);
      if (signUpType === SIGNUP_TYPE.GOOGLE)
        analytics.track(analytics.EVENTS.GOOGLE_SIGN_UP_STARTED);
      if (signUpType === SIGNUP_TYPE.APPLE) analytics.track(analytics.EVENTS.APPLE_SIGN_UP_STARTED);
      if (signUpType === SIGNUP_TYPE.MEDTRONIC)
        analytics.track(analytics.EVENTS.MEDTRONIC_SIGN_UP_STARTED);
      if (signUpType === SIGNUP_TYPE.DISNEY)
        analytics.track(analytics.EVENTS.DISNEY_SIGN_UP_STARTED);
    }

    onSignUpPatient(patientData.patient, signUpType);
  };

  const hasNameInput = () =>
    signUpType !== SIGNUP_TYPE.GOOGLE ||
    !isInputValid(patient?.firstName, 2) ||
    !isInputValid(patient?.lastName, 2);

  const getLabsLoadStatus = () => {
    if (loadingLabs) return undefined;
    if (errorLabs) return false;
    if (availableLabs && availableLabs.labs.length > 0) return true;
    return false;
  };

  const referringProvidersForMarket = () => {
    if (!availableReferringProviders) return [];

    const selectedMarketId = patientData.patient.labId;
    if (!selectedMarketId)
      return availableReferringProviders.referringProviders.map(provider => ({
        value: provider.name.toUpperCase(),
        label: provider.name.toUpperCase()
      }));

    return availableReferringProviders.referringProviders
      .filter(provider => provider.marketId === selectedMarketId)
      .map(provider => ({
        value: provider.name.toUpperCase(),
        label: provider.name.toUpperCase()
      }));
  };

  return (
    <Form autoComplete="on">
      <div>
        {hasEmailInput(signUpType) && (
          <VMarginWrapper>
            <TextField
              id="emailInput"
              label="EMAIL ADDRESS"
              value={patientData.patient.email}
              onChange={event => {
                updatePatientEmail(event.currentTarget.value);
              }}
              onBlur={() => checkIfBasicUserInfoSent()}
              autoComplete="email"
              spellCheck={false}
              placeholder="janedoe@email.com"
              status={patientData.emailInputValid === false ? 'error' : 'default'}
              helperText={patientData.emailInputValid === false ? 'Please enter a valid email' : ''}
            />
          </VMarginWrapper>
        )}

        {hasNameInput() && (
          <>
            <VMarginWrapper>
              <TextField
                id="firstNameInput"
                label="FIRST NAME"
                value={patientData.patient.firstName}
                onChange={event => {
                  updatePatientData('firstName', event);
                }}
                onBlur={() => checkIfBasicUserInfoSent()}
                autoComplete="given-name"
                spellCheck={false}
                placeholder="First name"
                description="Be sure to enter your full legal name for your medical record."
                status={patientData.firstNameInputValid === false ? 'error' : 'default'}
                helperText={
                  patientData.firstNameInputValid === false ? 'Please enter a valid first name' : ''
                }
              />
            </VMarginWrapper>
            <VMarginWrapper>
              <TextField
                id="lastNameInput"
                label="LAST NAME"
                value={patientData.patient.lastName}
                onChange={event => {
                  updatePatientData('lastName', event);
                }}
                onBlur={() => checkIfBasicUserInfoSent()}
                autoComplete="family-name"
                spellCheck={false}
                placeholder="Last name"
                description="Be sure to enter your full legal name for your medical record."
                status={patientData.lastNameInputValid === false ? 'error' : 'default'}
                helperText={
                  patientData.lastNameInputValid === false ? 'Please enter a valid last name' : ''
                }
              />
            </VMarginWrapper>
          </>
        )}

        <VMarginWrapper>
          <TextField
            id="PREFERRED NAME (OPTIONAL)"
            label="PREFERRED NAME (OPTIONAL)"
            description="Please enter a nickname or preferred name if different from your legal name."
            value={patientData.patient.preferredName}
            onChange={event => updatePatientData('preferredName', event)}
            spellCheck={false}
            placeholder="Preferred name"
          />
        </VMarginWrapper>

        {hasPasswordInput(signUpType) && (
          <PasswordFieldWithHints
            inputId="passwordInput"
            label="PASSWORD"
            placeholder={'Create a password'}
            value={patientData.patient.password || ''}
            onChange={event => updatePatientData('password', event)}
            status={patientData.passwordInputValid === false ? 'error' : 'default'}
          />
        )}

        <VMarginWrapper>
          <SelectField
            label="COUNTRY"
            placeholder="Select country"
            selected={patientData.countryOption}
            onChange={item => updateCountry(item)}
            options={COUNTRIES_OPTIONS}
            id="countryInput"
          />
        </VMarginWrapper>

        <VMarginWrapper>
          <TextField
            id="address1Input"
            label="ADDRESS LINE 1"
            value={patientData.patient.address?.address1}
            onChange={event => updatePatientData('address1', event)}
            placeholder="Address"
            status={patientData.address1InputValid === false ? 'error' : 'default'}
            helperText={
              patientData.address1InputValid === false ? 'Please enter a valid address line' : ''
            }
          />
        </VMarginWrapper>
        <VMarginWrapper>
          <TextField
            id="addressLineTwoInput"
            label="ADDRESS LINE 2 (OPTIONAL)"
            value={patientData.patient.address?.address2}
            onChange={event => updatePatientData('address2', event)}
            placeholder="Address"
          />
        </VMarginWrapper>

        {patientData.patient.address && isUS(patientData.patient.address.country) ? (
          <VMarginWrapper>
            <SelectField
              id="stateInput"
              label="STATE"
              placeholder="Select state"
              selected={patientData.stateOption}
              options={states}
              onChange={item => updateState(item)}
              status={patientData.stateInputValid === false ? 'error' : 'default'}
              helperText={patientData.stateInputValid === false ? 'Please select a state' : ''}
            />
          </VMarginWrapper>
        ) : (
          <InputWrapperMargin>
            <TextField
              id="stateInput"
              label="STATE"
              value={patientData.patient.address?.state}
              onChange={event => updatePatientData('state', event)}
              placeholder="State"
            />
          </InputWrapperMargin>
        )}

        <TwoFieldWrapper>
          <InputWrapperMargin>
            <TextField
              id="cityInput"
              label="CITY"
              placeholder="City"
              value={patientData.patient.address?.city}
              onChange={event => updatePatientData('city', event)}
              status={patientData.cityInputValid === false ? 'error' : 'default'}
              helperText={patientData.cityInputValid === false ? 'Please enter a city name' : ''}
            />
          </InputWrapperMargin>

          {patientData.patient.address && isUS(patientData.patient.address.country) ? (
            <InputWrapperMargin>
              <ZipField
                id="zipcodeInput"
                label="ZIP"
                value={patientData.patient.address.zipcode}
                zipType="US"
                placeholder="ZIP"
                autoComplete="postal-code"
                onChange={event => updatePatientData('zipcode', event)}
                status={patientData.zipcodeInputValid === false ? 'error' : 'default'}
                helperText={
                  patientData.zipcodeInputValid === false ? 'Please enter a ZIP code' : ''
                }
              />
            </InputWrapperMargin>
          ) : (
            <InputWrapperMargin>
              <ZipField
                id="zipcodeInput"
                label="ZIP"
                value={patientData.patient.address?.zipcode}
                placeholder="ZIP"
                onChange={event => updatePatientData('zipcode', event)}
                status={patientData.zipcodeInputValid === false ? 'error' : 'default'}
                helperText={
                  patientData.zipcodeInputValid === false ? 'Please enter a ZIP code' : ''
                }
              />
            </InputWrapperMargin>
          )}
        </TwoFieldWrapper>

        <VMarginWrapper>
          <PhoneFieldBasic
            id="phoneInput"
            label="PHONE NUMBER"
            placeholder={
              patientData.patient.address && isUS(patientData.patient.address.country)
                ? '(555) - 555-5555'
                : '(+XXXX)Phone Number'
            }
            value={patientData.patient.phone}
            onChange={event => updatePatientData('phone', event)}
            status={patientData.phoneInputValid === false ? 'error' : 'default'}
            helperText={
              patientData.phoneInputValid === false ? 'You must provide a phone number' : ''
            }
            country={isUS(patientData.patient.address.country) ? 'US' : 'other'}
          />
        </VMarginWrapper>
        <VMarginWrapper>
          <DateField
            id="birthdateInput"
            placeholder="Your date of birth"
            label="BIRTHDATE"
            value={
              patientData.patient.birthdate
                ? stringToDateUTC(patientData.patient.birthdate)
                : undefined
            }
            onChange={date => updateBirthdate(date ? dateToString(getUtcDate(date)) : '')}
            dateFormat="MM/dd/yyyy"
            autoComplete="off"
            status={patientData.birthdateInputValid === false ? 'error' : 'default'}
            helperText={
              patientData.birthdateInputValid === false
                ? 'Please select a valid date. You must be 18 years or older to schedule a consult.'
                : ''
            }
          />
        </VMarginWrapper>

        <VMarginWrapper>
          <SelectField
            id="gender-input"
            label="SEX ASSIGNED AT BIRTH"
            placeholder="Select"
            selected={patientData.genderOption}
            onChange={item => updateGender(item)}
            options={GENDER_INPUT_DEFAULTS}
          />
        </VMarginWrapper>

        <VMarginWrapper>
          <PreferredTimezoneSelectField
            id="preferredTimezoneInput"
            onChange={updatePreferredTimezone}
            selected={patientData.preferredTimeZoneOption?.label}
            label="PREFERRED TIMEZONE"
            placeholder="Select preferred timezone"
            onOptionsLoaded={loadSucceded => setTimeZonesLoadStatus(loadSucceded)}
            status={patientData.preferredTimezoneInputValid === false ? 'error' : 'default'}
            helperText={
              patientData.preferredTimezoneInputValid === false ? 'Please select a timezone.' : ''
            }
          />
        </VMarginWrapper>
        {signUpType !== SIGNUP_TYPE.DISNEY && (
          <VMarginWrapper>
            <SelectField
              id="patientLabInput"
              label="KINDBODY HOME LOCATION"
              placeholder="Select city"
              selected={patientData.labOption}
              onChange={updatePatientLab}
              options={availableLabOptions() || []}
              status={patientData.patientLabInputValid === false ? 'error' : 'default'}
              helperText={
                patientData.patientLabInputValid === false ? 'Please select a location.' : ''
              }
            />
          </VMarginWrapper>
        )}
        <VMarginWrapper id="referralSource">
          <SelectWithOptions
            inputId="referralSourceInput"
            label="HOW DID YOU HEAR ABOUT US?"
            value={patientData.patient.referralSource}
            placeholder={'Select one option'}
            lookup_key="patient_referral_source"
            onSelect={updateReferralSource}
            fullMode
            onOptionsLoaded={loadSucceded => setReferralSourcesLoadStatus(loadSucceded)}
            status={patientData.referralSourceInputValid === false ? 'error' : 'default'}
            helperText={
              patientData.referralSourceInputValid === false
                ? 'Please let us know how you heard about us.'
                : ''
            }
          />
        </VMarginWrapper>

        {isReferralInfluencerDetailsVisible(patientData.patient.referralSource) && (
          <VMarginWrapper id="referralSourceDetail">
            <TextField
              id="referralSourceDetailsInput"
              label="PLEASE ENTER THEIR FULL NAME"
              placeholder="First and last name"
              value={patientData.patient.referralSourceDetails}
              onChange={event => updatePatientData('referralSourceDetails', event)}
              status={patientData.referralSourceDetailsInputValid === false ? 'error' : 'default'}
              helperText={
                patientData.referralSourceDetailsInputValid === false
                  ? 'Please let us know their name.'
                  : ''
              }
            />
          </VMarginWrapper>
        )}

        {isReferralProviderDetailsVisible(patientData.patient.referralSource) && (
          <VMarginWrapper id="referralProviderDetail">
            <AutocompleteField
              label="PLEASE ENTER THEIR FULL NAME"
              placeholder="Full name"
              id="referralProviderDetailInput"
              onChange={event => updatePatientData('referralSourceDetails', event)}
              onSelect={option =>
                updatePatientData('referralSourceDetails', {
                  currentTarget: { value: option.value }
                })
              }
              options={referringProvidersForMarket()}
              minValueLengthToDisplayOptions={3}
              maxNumberOfDisplayedOptions={5}
              status={patientData.referralSourceDetailsInputValid === false ? 'error' : 'default'}
              helperText={
                patientData.referralSourceDetailsInputValid === false
                  ? 'Please let us know their full name.'
                  : ''
              }
            />
          </VMarginWrapper>
        )}

        <LegalAgreements
          sendSmsNotificationSelected={patientData.patient.sendSmsAppointmentNotification}
          sendMarketingEmailSelected={patientData.patient.sendMarketingEmail}
          onSmsNotificationChange={toggleSendSmsAppointmentNotificationFlag}
          onMarketingEmailChange={toggleSendMaketingEmailFlag}
        />

        <ButtonWrapperPrivacy>
          <Button
            label={'CREATE ACCOUNT'}
            onClick={e => {
              if (!loading) {
                onSignUp(e);
              }
            }}
            category="primary"
            isDisabled={loading}
            fullWidth={true}
          />
        </ButtonWrapperPrivacy>
        <TermsOfUse />
        {signInLinkIsVisible && (
          <GoToSignIn
            onSignInClick={() => {
              window.removeEventListener('beforeunload', handleBeforeUnload);
              history.push(pageUrl.login());
            }}
          />
        )}
      </div>

      <SignUpFormModal
        referralSourcesLoadStatus={referralSourcesLoadStatus}
        timeZonesLoadStatus={timeZonesLoadStatus}
        locationsLoadStatus={getLabsLoadStatus()}
      />
    </Form>
  );
};
