import React from 'react';
import { useHistory } from 'react-router-dom';

import { LoadedEntities, PurchasedProduct } from 'kb-shared/booking/types/types';
import { AppointmentType, TimeSlot, PartnerClinicDetails } from 'kb-shared/types';
import { PurchaseConfirmation } from 'screens/Book/steps/Success/PurchaseConfirmation/PurchaseConfirmation';

import { DashboardButton } from './Success.styled';

export const SuccessStep = ({
  appointmentType,
  selectedAppointmentTypes,
  selectedProduct,
  selectedTimeSlot,
  purchasedProduct,
  partnerClinicSelected
}: {
  appointmentType?: AppointmentType;
  selectedAppointmentTypes: Array<AppointmentType>;
  selectedProduct?: LoadedEntities['selectedProduct'];
  selectedTimeSlot?: TimeSlot;
  purchasedProduct?: PurchasedProduct;
  partnerClinicSelected: PartnerClinicDetails | null;
}): JSX.Element => {
  const history = useHistory();
  let appointmentName = '';

  if (selectedProduct && selectedProduct.type === 'appointment') {
    const selectedAppointment = selectedProduct.data;
    appointmentName = selectedAppointment.name;
  }
  if (!appointmentType && selectedAppointmentTypes) {
    appointmentName = selectedAppointmentTypes[0]?.name;
  }
  return (
    <>
      <PurchaseConfirmation
        purchase={purchasedProduct}
        timeSlot={selectedTimeSlot ?? null}
        partnerClinicDetails={partnerClinicSelected}
        appointmentName={appointmentName}
        history={history}
      />
      <DashboardButton href="/">Go To Dashboard</DashboardButton>
    </>
  );
};
