import { ApolloError, useMutation } from '@apollo/client';
import React, { useState } from 'react';

import { CreateAppointmentResponseData, Membership, ValidPromo, AppointmentType } from 'kb-shared';
import { bookingAnalytics } from 'kb-shared/booking/utils/bookingAnalytics';
import { PATIENT_APPOINTMENTS } from 'kb-shared/graphql/queries';
import { isCoachingAppointment } from 'kb-shared/utilities/appointment.helper';
import { analytics } from 'utilities/analytics';
import { showErrorToast } from 'utilities/notificationUtils';

import { CREATE_APPOINTMENT } from '../Confirm.graphql';
import { generateErrorString } from '../Confirm.utils';
import { useIsPaymentOptional } from './CreateAppointmentForm.hooks';
import { AppointmentData, Props } from './CreateAppointmentForm.types';
import { PaymentForm } from './PaymentForm';

export const CreateAppointmentForm = ({
  product,
  timeSlot,
  onBookAppointment,
  providerId
}: Props) => {
  const [loading, setLoading] = useState(false);
  const [validPromo, setValidPromo] = useState<ValidPromo | null>(null);
  const [paymentTermsChecked, setPaymentTermsChecked] = useState(false);
  const { isPaymentOptional } = useIsPaymentOptional(product?.data as AppointmentType | Membership);

  // only used in payment form
  const [createAppointment] = useMutation<
    CreateAppointmentResponseData,
    {
      timeSlotId: number;
      appointmentTypeId: number;
      stripeTokenId?: string | null;
      discountCode?: string | null;
      kbStripeCardStripeIdentifier?: string;
      providerId?: number;
    }
  >(CREATE_APPOINTMENT, {
    refetchQueries: [{ query: PATIENT_APPOINTMENTS }]
  });

  if (product?.type !== 'appointment' || !timeSlot) return null;

  const purchaseAppointmentSucceeded = ({
    appointmentData,
    creditCardUsed
  }: {
    appointmentData?: AppointmentData;
    creditCardUsed: boolean;
  }) => {
    if (!appointmentData) return;

    setLoading(false);
    onBookAppointment(appointmentData.appointment);
    bookingAnalytics.track.npvAppointmentBookingSucceeded(
      appointmentData.appointment,
      creditCardUsed
    );
    bookingAnalytics.track.appointmentBookingSucceeded(appointmentData.appointment, creditCardUsed);
  };

  const onError = (err: ApolloError | null) => {
    const errorString = generateErrorString(err);

    analytics.track(analytics.EVENTS.APPOINTMENT_BOOKING_FAILED, { error: errorString });

    setLoading(false);
    showErrorToast(errorString);
  };

  const appointmentType = product.data;
  const shouldChargeAtBooking = product.data.shouldChargeAtBooking;
  const canSkipPayment =
    appointmentType.rateCents === 0 ||
    (shouldChargeAtBooking === false && isCoachingAppointment(appointmentType));

  const createAppointmentWithoutPaymentInfo = async () => {
    try {
      const response = await createAppointment({
        variables: {
          timeSlotId: parseInt(timeSlot.id, 10),
          appointmentTypeId: parseInt(appointmentType.id, 10),
          providerId: providerId
        }
      });
      purchaseAppointmentSucceeded({
        appointmentData: response?.data?.createAppointment,
        creditCardUsed: false
      });
    } catch (error) {
      onError(error as ApolloError);
    }
  };

  return (
    <PaymentForm
      appointment={appointmentType}
      product={product}
      paymentTermsChecked={paymentTermsChecked}
      loading={loading}
      onPaymentTermsChecked={checked => setPaymentTermsChecked(checked)}
      onValidPromoChange={validPromo => setValidPromo(validPromo)}
      onSubmit={(stripeTokenId?: string, kbStripeCardStripeIdentifier?: string) => {
        setLoading(true);
        if (stripeTokenId) {
          createAppointment({
            variables: {
              providerId: providerId,
              timeSlotId: parseInt(timeSlot.id, 10),
              appointmentTypeId: parseInt(appointmentType.id, 10),
              stripeTokenId,
              discountCode: validPromo?.code
            }
          })
            .then(response => {
              purchaseAppointmentSucceeded({
                appointmentData: response.data?.createAppointment,
                creditCardUsed: true
              });
            })
            .catch(onError);
        } else if (kbStripeCardStripeIdentifier) {
          createAppointment({
            variables: {
              providerId: providerId,
              timeSlotId: parseInt(timeSlot.id, 10),
              appointmentTypeId: parseInt(appointmentType.id, 10),
              kbStripeCardStripeIdentifier,
              discountCode: validPromo?.code
            }
          })
            .then(response => {
              purchaseAppointmentSucceeded({
                appointmentData: response.data?.createAppointment,
                creditCardUsed: true
              });
            })
            .catch(onError);
        } else {
          onError(null);
        }
      }}
      noPayment={canSkipPayment ? createAppointmentWithoutPaymentInfo : undefined}
      onPaymentOptional={isPaymentOptional ? createAppointmentWithoutPaymentInfo : undefined}
    />
  );
};
