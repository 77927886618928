import { PromoCode as PromoCodeBase } from 'screens/Book/components/PromoCode/PromoCode';
import styled from 'styled-components';

export const PaymentFormContainer = styled.div`
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  margin-bottom: 30px;
  width: 100%;
`;

export const PromoCode = styled(PromoCodeBase)`
  width: 100%;
  flex: 0 0 auto;
  margin-top: 15px;
  text-align: left;
  justify-content: flex-start;
`;
