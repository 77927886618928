import React from 'react';
import { useHistory } from 'react-router-dom';

import { StepTrackerLayout } from 'components/v2/Layouts/StepTrackerLayout/StepTrackerLayout';
import { pageUrl } from 'utilities/pageUrl';

import { MembershipConfirmationCard } from './MembershipConfirmationCard';

export const MembershipActivationSuccess = () => {
  const history = useHistory();

  return (
    <StepTrackerLayout
      title="Membership Activated"
      stepNames={['Services', 'Location', 'Activate']}
      selectedStep={3}
      hideBackButton
      renderMainContent={() => (
        <MembershipConfirmationCard
          onClick={() => {
            history.push(pageUrl.booking.bookingQuiz());
            window.location.reload();
          }}
        />
      )}
    />
  );
};
