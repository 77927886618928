import { useQuery } from '@apollo/client';
import React from 'react';

import IntakeFormCard from 'components/IntakeFormCard';
import { Loader } from 'components/Loader/Loader';
import { UpcomingAppointment } from 'components/UpcomingAppointment';
import { TODOS } from 'kb-shared/graphql/queries';

import { PartnerLocationConfirmationCard } from '../../Location/PartnerLocation/PartnerLocationConfirmationCard';
import { CardContainer, Container } from './PurchaseConfirmation.styled';
import { Props, TodoResult } from './PurchaseConfirmation.types';
import { hasIntakeTodo } from './PurchaseConfirmation.utils';

export const PurchaseConfirmation = (props: Props) => {
  const { purchase, timeSlot, history, partnerClinicDetails, appointmentName } = props;
  const { data, loading } = useQuery(TODOS) as TodoResult;
  const todos = data && data.patientTodos;

  if (partnerClinicDetails) {
    return (
      <Container id="google-tag-manager-confirmation">
        {partnerClinicDetails && (
          <PartnerLocationConfirmationCard
            partnerClinicDetails={partnerClinicDetails}
            appointmentName={appointmentName}
          />
        )}
      </Container>
    );
  }

  if (purchase?.type === 'appointment') {
    // @ts-ignore
    const appointment = purchase.data.id ? purchase.data : purchase.data.appointment;
    const showIntakeCard = todos && hasIntakeTodo(todos) && appointment.intakeFormRequired;

    if (loading) {
      return (
        <Container>
          <Loader container />
        </Container>
      );
    }

    return (
      <Container id="google-tag-manager-confirmation">
        {!partnerClinicDetails && (
          <CardContainer>
            <UpcomingAppointment
              appointment={appointment}
              timeSlot={timeSlot}
              type={appointment.appointmentType}
              isConfirmation={true}
              onReschedule={() => window.location.reload()}
              onAppointmentCancelled={() => {
                props.history.push('/');
              }}
            />
          </CardContainer>
        )}
        {!!showIntakeCard && (
          <CardContainer>
            <IntakeFormCard history={history} confirmation />
          </CardContainer>
        )}
      </Container>
    );
  }

  return null;
};
